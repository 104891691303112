.header {
    position: sticky;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #ef4815;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(0, 0, 0);
    background: linear-gradient(102deg, rgba(0, 0, 0, 1) 0%, rgba(26, 26, 26, 1) 29%, rgba(255, 255, 255) 100%, rgb(255, 255, 255) 100%);

    .logo {
        height: 100%;

        img {
            height: 90%;
        }
    }

    .nav {
        display: flex;
        margin: 0 1%;

        .links a {
            color: white;
            margin: 0 15px;
            font-weight: 700;
            text-decoration: none;
            transition: .3s;

            &.active {
                color: #ef4815;
            }

            &:hover {
                color: #ef4815;
                cursor: pointer;
            }
        }

        .languages {
            >button {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 40px;
                height: 20px;
                font-size: 15px;
                background-color: #3b3b68;
                color: white;
                border: none;
                border-radius: 15px;

                &::after {
                    content: "↓";
                    font-size: 10px;
                    transition: ease-in-out .3s;
                }

                span {
                    display: none;
                }

                :hover>&::after {
                    color: #ef4815;
                    transform: scale(2);
                    transition: ease-in-out .3s;
                }
            }

            position: relative;

            ol {
                width: 110px;
                position: absolute;
                z-index: 1;
                top: 40px;
                right: -10px;
                background-color: #202020;
                border-radius: 0 0 0 15px;
                list-style: none;

                button {
                    width: 100%;
                    border: none;
                    background-color: transparent;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 10px;
                    color: white;
                    font-size: 15px;
                    transition: ease-in-out .3s;

                    &:hover {
                        cursor: pointer;
                        color: #ef4815;
                    }

                    span {
                        font-weight: 700;
                    }
                }
            }

        }
    }

    .menuBar {
        display: none;
        margin-right: 1%;

        .bar1,
        .bar2,
        .bar3 {
            width: 35px;
            height: 5px;
            background-color: #ef4815;
            border-radius: 15px;
            margin: 6px 0;
            transition: 0.4s;
        }


    }

    &.open .bar1 {
        transform: translate(0, 11px) rotate(-45deg);
    }

    &.open .bar2 {
        opacity: 0;
    }

    &.open .bar3 {
        transform: translate(0, -11px) rotate(45deg);
    }

}

@media (max-width: 755px) {
    .header {
        .menuBar {
            display: block;
        }

        .nav {
            display: none;
        }

        &.open {

            .nav {
                display: block;
                position: absolute;
                top: 60px;
                width: 100%;
                height: calc(100vh - 100px);
                margin: 0;
                background: linear-gradient(100deg, rgba(0, 0, 0, 1) 0%, rgba(26, 26, 26, 1) 29%, rgba(119, 119, 119, 0.41208202030812324) 100%, rgba(140, 140, 140, 1) 100%);

                .links {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    a {
                        margin-top: 30px;
                    }
                }

                .languages {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 40px;
                    flex-direction: column;

                    ol {
                        position: relative;
                        right: 0;
                        background-color: #535353;
                        border-radius: 5px;
                        width: 200px;

                        li {
                            margin: 10px;
                        }
                    }
                }
            }
        }
    }
}