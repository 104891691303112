footer {
    color: white;
    background-color: #000000;
    font-weight: 700;

    .logoTop {
        background-color: #000000;
        display: flex;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            max-height: 150px;
            object-fit: contain;
        }
    }


    .division {
        padding: 0 1%;
        display: flex;
        text-align: center;

        h1 {
            margin-bottom: 20px;
        }

        >div {
            width: calc(100%/3);
            height: 100%;
        }

        .logo img {
            width: 100%;
            height: 100%;
            max-height: 100px;
            object-fit: contain;
        }


        ol {
            padding: 0;

            li {
                display: flex;
                justify-content: center;
                font-size: 28px;
            }
        }
        .socialMedia {
            padding-left: 10%;
            padding-top: 10px;
    
            img {
                width: 30px;
                margin: 0 10px;
            }
        }

        .contact {
            position: relative;

            img {
                float: left;
                width: 30px;
                margin-right:20px;
            }

            div {
                padding: 10px 0;
                text-align: start;
                margin-left: 10px;
            }
            a {
                text-decoration:none;
                color: #ffffff;
            }
        }

    }

    

    .copyright {
        text-align: center;
        font-size: 20px;
        padding-bottom: 15px;
    }
}

@media (max-width: 730px) {
    hr {
        display: block;
    }

    img {
        max-width: 100%;
    }

    li {
        font-size: 15px;
    }

    .logo {
        height: 100%;
    }

    .division {
        font-size: 12px;
        padding: 5%;
        flex-direction: column;


        h1 {
            padding: 25px;
            text-decoration: underline;
        }

        >div {
            min-width: 100%;
        }

        .contact {
            padding: 0 10%;
        }
    }

    .socialMedia {
        display: flex;
        justify-content: center;
    }
}