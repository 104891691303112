body {
    min-width: 320px;
    background-color: #323232;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.App {
    display: flex;
    flex-direction: column;
}

.suiteHeader {
    position: fixed;
    top: 60px;
    z-index: 2;
    color: white;
    background-color: #000000;
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 0 0 80px 0;

    span {
        color: #ef4815;
        a{
            color:#ffffff;
            text-decoration:none;
        }
    }
}

.banner {
    position: relative;
    background-color: #3b3f61;
    height: 100%;

    .bannerImg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: -1;
    }

    .bannerInfo {
        color: white;
        font-size: 4vw;
        font-weight: 900;
        background-color: #00000054;
        position: absolute;
        bottom: 50px;
        right: 0;
        width: 40%;
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1% 10px;

        & :nth-child(odd) {
            color: #df3d3d;
            font-size: 3vw;
        }
    }

    .socialMedia {
        position: absolute;
        bottom: 10px;
        right: 10px;

        img {
            width: 30px;
            margin: 0 5px;
            color: white;
        }

    }
}

.slogan {
    padding: 10px 20px;
    text-align: center;
    background-color: #3b3f61;
    color: white;
    font-size: 2.2vw;
    font-weight: 700;
    display: flex;
    justify-content: center;

    >div {
        max-width: 500px;
    }
}

.video {
    width: 100%;
    aspect-ratio: 16/9;
    padding: 10px 5%;

    >div {
        width: 100%;
        height: 100%;
        background-color: #d179c3;
        display: flex;
        justify-content: center;
        align-items: center;
        color: red;
        font-size: 30px;
    }
}

.pourquoi {

    color: white;
    padding: 30px 5%;
    overflow: hidden;

    >* {
        text-align: center;
    }

    >div {
        position: relative;
    }

    h1 {
        color: #ef4815;
    }

    p {
        font-size: 20px;
        font-weight: 700;
    }

    ol {
        list-style: none;
        text-align: left;
        font-weight: 700;
        width: 55%;
        position: absolute;
        z-index: 1;
    }

    >div>img {
        height: 100%;
        object-fit: cover;
        transform: translateX(50%);

    }

    .reserver {
        display: flex;
        justify-content: center;
        a{
            text-decoration: none;
            color: #ffffff;
        }
    }

    button {
        margin-top: 20px;
        padding: 10px 20px;
        font-size: 30px;
        font-weight: 700;
        background-color: #ef4815;
        color: white;
        border: none;
        border-radius: 50px;

        &:hover {
            cursor: pointer;
        }
    }
}

.comment {
    padding: 30px 5%;
    margin: 20px 1%;
    background-color: #7c7c7c;
    text-align: center;
    color: #dbdbdb;
    font-size: 20px;

    h1,
    h2 {
        color: white;
    }

    >div {
        display: flex;
        position: relative;

        >div {
            padding: 1%;

            img {
                margin-top: 30px;
                height: 60px;
            }
        }
    }
}

.about {
    padding: 30px 5%;
    color: white;
    position: relative;
    overflow: hidden;

    >div {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    h1 {
        color: white;
        text-align: center;
    }

    img {
        height: 100%;
        object-fit: cover;
    }

    p {
        width: 100%;
        height: 80%;
        padding: 10px 5%;
        background-color: #0000002d;
        display: flex;
        align-items: center;

    }

    div {
        position: relative;

        div {
            text-align: left;
            font-size: 20px;
            font-weight: 700;
            width: 60%;
            height: 100%;
            position: absolute;
            z-index: 1;
            display: flex;
            align-items: center;
        }
    }



    div:nth-child(even) {
        img {
            transform: translateX(50%);
        }

        div {
            left: 0;
        }
    }

    div:nth-child(odd) {
        img {
            transform: translateX(-43%);
        }

        div {
            right: 0;
        }
    }
}

.services {
    margin: 30px 5%;
    padding: 20px 1%;
    background-color: #7b7b7b;
    color: #cccccc;
    font-size: 20px;

    >div {
        display: flex;
        margin-bottom: 20px;
    }

    h1 {
        color: #ef4815;
        margin-bottom: 30px;
        text-align: center;
    }

    h2 {
        color: black;
        font-weight: 700;
    }

    img {
        width: 30px;
        margin: 0 2% 0 0;
        top: 0;
    }
}

.preoccupations {
    width: 100%;
    height: 100%;
    background-color: #7b7b7b;
    display: flex;
    position: relative;

    >div {
        width: 70%;
        padding: 10px 2%;
    }

    img {
        width: 30%;
        object-fit: cover;
    }

    textarea {
        width: 100%;
        resize: none;
        padding: 2% 1%;
        outline: none;
    }

    .message {
        height: 200px;
    }
}

.cars {
    margin: 30px;
    background-color: #343151;

    .slick-slide {
        padding: 20px 5px;
    }

    .carte {
        position: relative;
        overflow: hidden;
        background: rgb(245, 200, 94);
        background: linear-gradient(90deg, rgb(255, 210, 106) 0%, rgb(255, 98, 0) 100%);
        color: white;
        text-align: center;
        border-radius: 15px;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background-color: #000000;
            transform: skewY(-5deg) translateX(-50%);
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 0;
        }

        >* {
            position: relative;
            z-index: 2;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        .info1 {
            font-size: 2vw;
        }

        .info2 {
            display: flex;
            padding: 10px 5%;
            margin-top: 10px;
            justify-content: space-between;
            border-top: 1px solid gray;

        }
    }
}

.drivers {
    margin: 30px;
    background-color: #343151;

    .slick-slide {
        padding: 20px 5px;
    }

    .carte {
        position: relative;
        overflow: hidden;
        background: #d5d5d5;
        color: white;
        text-align: center;
        border-radius: 15px;

        >div {
            width: 100%;
            display: flex;

            img {
                width: 30%;
                object-fit: cover;
            }


        }

        .info {
            font-size: 3vw;
            width: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .nom {
                color: #252865;
                font-weight: 700;
            }
            .tardescripyionif {
                color: #252865;
            }
            .chauffeur{
                color: black;
            }
            .tel,
            .mail {
                color: #252865;
                text-align: left;
                font-weight: 700;
            }
        }
    }
}

.appreciation {
    margin: 30px;

    .carte {
        width: 100%;
        padding: 2%;
        text-align: center;
        color: white;
        font-size: 15px;
        font-weight: 500;

        .stars {
            padding-top: 5px;
            display: flex;
            justify-content: center;

            .star {
                content: '';
                width: 15%;
                position: relative;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        >div {
            width: 100%;
            padding: 2%;
            border: 1px solid gainsboro;
            border-radius: 10px;
        }
    }
}

@media (max-width: 730px) {
    .suiteHeader {
        padding: 10px;
        font-size: 15px;
    }

    .slogan {
        font-size: 20px;
    }

    .pourquoi {

        >div>img {
            display: none;
        }
    }

    .comment>div {
        flex-direction: column;
    }

    .about {
        img {
            display: none;
        }

        div div {
            position: relative;
            padding-top: 30px;
            width: 100%;
            text-align: center;
        }
    }

    .services>div {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .preoccupations {
        img {
            display: none;
        }

    }

    .cars .carte .info1 {
        font-size: 16px;
    }

    .drivers .carte>div img {
        width: 30%;
    }

    .drivers .carte .info {
        font-size: 4vw;
        width: 70%;
    }

    .preoccupations>div {
        width: 100%;
    }
}

@media (max-width: 816px) {
    .pourquoi {

        >div>img {
            display: none;
        }

        ol {
            position: relative;
            width: 100%;
        }
    }

}

@media (max-width: 580px) {
    .cars .carte .info2 {
        display: none;
    }

}